



.ml-5 {
    margin-right: 350px;
}

.gris{
    background-color: gainsboro;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
.blanco{
    background-color: white;
}
.versalita{
    font-variant: small-caps;
}
.verde{
    color: rgb(28, 185, 28);
}
.verdeBackground{
    background-color: rgb(85, 226, 85);
}
.medida{
    height: 40px;
}
.text-justify {
    text-align: justify;
}

.tabulacion{
    text-indent: 40px;
}
.tabulacionAct{
    text-indent: 78px;
}
.tabulacionPresent{
    text-indent: 100px;
}
/*****/




.ml-5 {
    margin-right: 350px;
}


.floating-image {
    width: 400px;
    height: auto;
    position: relative;
    top: -200px;
    left: 100px;
    animation: floatAndDistort 3s infinite ease-in-out;
}

.mt-n5 {
    margin-top: -3rem; /* Ajuste para que la imagen sobresalga hacia arriba */
}

@keyframes floatAndDistort {
    0% {
        transform: translateX(0) scale(1);
    }

    50% {
        transform: translateX(5px) scale(1.25);
    }
   
    100% {
        transform: translateX(0) scale(1);
    }
}
@media (max-width: 1200px) {

    .floating-image, .body-container {
    display: none;
    }
    .ml-5 {
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .gris {
        margin-left: 0;
        margin-top: 20px;
        padding: 15px;
    }

    .floating-image, .body-container {
    display: none;
    }
}

.floating-image {
    width: 100%;
    height: auto;
    animation: floatAndDistort 2s infinite ease-in-out;
    /* Optional: Add a border-radius to soften the edges */
    border-radius: 15px;
}

